import React from "react";
import { Link } from "react-router-dom";
function HomeButton() {
  return (
    <div className="div_btn_home">
      <Link to={"/projects"} className="btn_home">
        See my Works !
      </Link>
    </div>
  );
}

export default HomeButton;
