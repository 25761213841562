import React from "react";
import ContactSection from "../Components/ContactSection";
import Footer from "../Components/Footer";
import Map from "../Components/Map";
import "../Styles/pages.css";

function Contact() {
  return (
    <div>
      <ContactSection />
      <div>
        <Map />
      </div>
      <Footer />
    </div>
  );
}

export default Contact;
