import React from "react";
import { Link } from "react-router-dom";
function FooterCol({ heading, link_list }) {
  return (
    <div>
      <div>
        <h1 className="foot_col_head">{heading}</h1>
        <ul>
          {link_list.map((obj, index) => {
            return (
              <li key={index} className="foot_col_li">
                {obj.type === "link" ? (
                  <Link to={obj.path} className="foot_col_link">
                    {obj.title}
                  </Link>
                ) : (
                  <a
                    href={obj.path}
                    target="_blank"
                    rel="noreferrer"
                    className="foot_col_a"
                  >
                    {obj.title}
                  </a>
                )}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default FooterCol;
