import React from "react";
import HomePtext from "./HomePtext";
import { Link } from "react-router-dom";
import "../Styles/contact_block.css";
function ContactBlock() {
  return (
    <div>
      <div className="contact_container">
        <div className="contact_wrapper">
          <HomePtext>
            <p className="contact_wrapper_p">Have a project in mind</p>
          </HomePtext>
          <h2 className="contact_wrapper_heading">Let me help you</h2>
          <Link to={"/contact"} className="contact_wrapper_btn">
            Contact Now
          </Link>
        </div>
      </div>
    </div>
  );
}

export default ContactBlock;
