import React, { useState, useEffect } from "react";
import "../Styles/progressbar.css";
function SkillBar({ title, value, delay }) {
  const [bar, setBar] = useState();
  useEffect(() => {
    setTimeout(() => {
      const newBar = {
        opacity: 1,
        width: `${value}%`,
        transition: `1.5s ease ${delay}s`,
      };
      setBar(newBar);
    }, 1000);
    return () => {};
  }, [delay, title, value]);

  return (
    <div className="progress-wrapper">
      <h2 className="progress-title">{title}</h2>
      <div className="progress-bar">
        <div className="progress-done" style={bar}>
          {value}%
        </div>
      </div>
    </div>
  );
}

export default SkillBar;
