import React from "react";
import SectionTitle from "../Components/SectionTitle";
import des from "../Assets/ui-des.png";
import bug from "../Assets/bug-fix.png";
import dev from "../Assets/ui-dev.png";
import HomePtext from "./HomePtext";
function ServicesSection() {
  return (
    <div>
      <SectionTitle>
        <p className="service_p">What i will do for you</p>
        <h1 className="service_h2">Services</h1>
      </SectionTitle>
      <div className="services_all">
        <div className="service_wrapper">
          <div className="servicesitem_icon">
            <img src={des} alt="service_des" className="serviceicon" />
          </div>
          <div className="servicestitle">
            <h3 className="service_h3">Web Design</h3>
          </div>
          <HomePtext>
            <p className="servicecontent">
              I do ui/ux design for the website that helps website to get a
              unique look.
            </p>
          </HomePtext>
        </div>
        <div className="service_wrapper">
          <div className="servicesitem_icon">
            <img src={bug} alt="service_des" className="serviceicon" />
          </div>
          <div className="servicestitle">
            <h3 className="service_h3">Bug Fixing</h3>
          </div>
          <HomePtext>
            <p className="servicecontent">
              I can trace a bug and fix it by changing some lines of code.
            </p>
          </HomePtext>
        </div>
        <div className="service_wrapper">
          <div className="servicesitem_icon">
            <img src={dev} alt="service_des" className="serviceicon" />
          </div>
          <div className="servicestitle">
            <h3 className="service_h3">Web Dev</h3>
          </div>
          <HomePtext>
            <p className="servicecontent">
              I also develop the websites. i create high performance website
              with Blazing speed.
            </p>
          </HomePtext>
        </div>
      </div>
    </div>
  );
}

export default ServicesSection;
