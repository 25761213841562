import React from "react";
import FooterCol from "./FooterCol";
import HomePtext from "./HomePtext";
import "../Styles/footer.css";
function Footer() {
  return (
    <div>
      <div className="footer_container">
        <div className="footer_col1">
          <h1 className="foot_col1_title">Bharath</h1>
          <HomePtext>
            <p className="foot_col1_p">
              A freelance web designer and developer from Puducherry, India.{" "}
              <br />
              <span className="foot_bio">
                "I make myself in such a way that I'm always part of the
                solution, not the problem"
              </span>
            </p>
          </HomePtext>
        </div>
        <div className="footer_col2">
          <FooterCol
            heading="Important Links"
            link_list={[
              {
                title: "Home",
                path: "/home",
                type: "link",
              },
              {
                title: "About",
                path: "/about",
                type: "link",
              },
              {
                title: "Skills",
                path: "/skills",
                type: "link",
              },
              {
                title: "Projects",
                path: "/projects",
                type: "link",
              },
              {
                title: "Contact",
                path: "/contact",
                type: "link",
              },
            ]}
          />
        </div>
        <div className="footer_col3">
          <FooterCol
            heading={"Contact Info"}
            link_list={[
              {
                title: "+91 9500583357",
                path: "tel:+919500583357",
              },
              {
                title: "bharath_dev@outlook.com",
                path: "mailto: bharath_dev@outlook.com",
              },
              {
                title: "Karaikal, Puducherry, India ",
                path: "https://www.google.com/maps/@10.9307692,79.8331814,14z",
              },
            ]}
          />
        </div>
        <div className="footer_col4">
          <FooterCol
            heading={"Other Links"}
            link_list={[
              {
                title: "LinkedIn",
                path: "https://linkedin.com/in/bharath-balasubramaniam-0a7869192",
              },
              {
                title: "Github",
                path: "https://github.com/bharath-balasubramaniam",
              },
            ]}
          />
        </div>
      </div>
      <div className="copyright">
        <p className="copright_p">
          © 2024 - created by Bv. <span className="copyright_span">|</span>{" "}
          <span className="copyright_span1">Why don't we be ourselves?!</span>
        </p>
      </div>
    </div>
  );
}

export default Footer;
