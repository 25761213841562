import React from "react";
import "../Styles/pages.css";
import TypingTextAnimation from "react-typing-text-animation";
import ReactTypingEffect from "react-typing-effect";
import home_img from "../Assets/images/home.png";
import HomePtext from "../Components/HomePtext";
import HomeButton from "../Components/HomeButton";
import arrow_down from "../Assets/arrow_down.svg";
// import github from "../Assets/social_media/github-1.png";
// import linkedin from "../Assets/social_media/linkedin.png";
// import whatsapp from "../Assets/social_media/whatsapp.png";
import scroll from "../Assets/scroll-down-arrow.svg";
import AboutSection from "../Components/AboutSection";
import ContactBlock from "../Components/ContactBlock";
import Footer from "../Components/Footer";
import SkillsSection from "../Components/SkillsSection";
import ProjectSection from "../Components/ProjectSection";
function Home() {
  return (
    <>
      <div className="mine">
        <div className="container">
          <h1 className="my_heading">
            <span className="uni">Hello,</span>
            <span className="uni_typing">
              <TypingTextAnimation
                className="uni_type"
                text="I'm Bharath"
                cursorDuration="1s"
                cursor={<></>}
              />
            </span>
            <div className="text_type">
              <ReactTypingEffect
                text={[
                  "Full Stack Developer",
                  "MERN Stack Developer",
                  "MEVN Stack Developer",
                  "Front End Developer",
                  "Photographer",
                  "Traveller",
                  "Freelancer ",
                ]}
                speed={200}
                eraseSpeed={100}
                eraseDelay={100}
                typingDelay={50}
                cursorRenderer={() => <>{}</>}
              />
            </div>
          </h1>
          <div className="home_bg_img">
            <img src={home_img} alt="img.jpg" />
          </div>
          <div className="home_text">
            <HomePtext>
              <p className="home_content">
                I'm a self taught programmer and enthusiastic learner in
                computer science. I did my bachelors in Electronics and
                Communication Enginerring. I have done a Fullstack developer
                bootcamp and doing some courses in latest technology. I'm in
                this web development field for the past four years and have
                earned a great reputation.
              </p>
              <h2 className="home_bio">
                "I stand on a fine spot where Technology fuses into Code".
              </h2>
            </HomePtext>
            <HomeButton />
          </div>
          <div className="follow_home">
            <p className="social_text">Connect</p>
            <img src={arrow_down} alt="social_media_arrow" className="arrow" />     
            <a
              href="https://www.linkedin.com/in/bharath-balasubramaniam-dev/"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              <img
                src={require("../Assets/social_media/linkedin.png")}
                alt="linkedin"
                className="icon"
              />
            </a>
            <a
              href="https://github.com/bharath-balasubramaniam"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              <img
                src={require("../Assets/social_media/github-1.png")}
                alt="github"
                className="icon"
              />
            </a>
            <a
              href="https://wa.me/+919500583357"
              target="_blank"
              rel="noreferrer"
              className="link"
            >
              <img
                src={require("../Assets/social_media/whatsapp.png")}
                alt="whatsapp"
                className="icon"
              />
            </a>
          </div>
          <div className="scroll_arrow">
            <p className="scroll_text">Scroll</p>
            <img src={scroll} alt="scroll_arrow" className="arrow ar1" />
          </div>
        </div>
      </div>
      <AboutSection />
      <SkillsSection />
      <ProjectSection />
      <ContactBlock />
      <Footer />
    </>
  );
}

export default Home;
