import React, { useState, useEffect } from "react";
import ContactBlock from "../Components/ContactBlock";
import Footer from "../Components/Footer";
import "../Styles/pages.css";
import SectionTitle from "../Components/SectionTitle";
import { MdSearch } from "react-icons/md";
import ProjectItem from "../Components/ProjectItem";
import projects from "../Data/projects";
import styled from "styled-components";
const ProjectsStyle = styled.div`
  padding: 5rem 0;
  padding-bottom: 1rem;
  .projects-items {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 5rem;
    margin: 0rem 9rem;
    margin-top: 5rem;
  }
  .projects-search {
    position: relative;
    width: 300px;
    margin-top: 5rem;
    margin-left: 10rem;
  }
  .projects-search input {
    width: 100%;
    font-size: 2rem;
    padding: 0.8rem;
    color: #a7a9be;
    font-weight: 600;
    border-radius: 6px;
    font-family: "Zen Kaku Gothic Antique", sans-serif;
    outline: none;
    border: none;
  }
  .projects-search .searchIcon {
    position: absolute;
    width: 2.5rem;
    right: 1rem;
  }

  @media only screen and (max-width: 1360px) {
    .projects-items {
      margin: 0rem 3rem;
    }
    .projects-search {
      margin-top: 2.5rem;
      margin-left: 4rem;
      margin-bottom: 2.5rem;
    }
  }
  @media only screen and (max-width: 860px) {
    .projects-items {
      margin: 0rem 2rem;
    }
  }
  @media only screen and (max-width: 768px) {
    .projects-items {
      gap: 2.5rem;
    }
  }
  @media only screen and (max-width: 480px) {
    .projects-search,
    .projects-search form,
    .projects-search input {
      width: 95%;
      margin: 2rem 0rem;
      position: relative;
      left: 1rem;
    }
    .projects-search .searchIcon {
      right: 3rem;
    }
  }
`;
function Projects() {
  const [searchText, setSearchText] = useState("");
  const [projectData, setProjectData] = useState(projects);
  useEffect(() => {
    if (searchText === "") return;
    setProjectData(() =>
      projects.filter((item) =>
        item.name.toLowerCase().match(searchText.toLowerCase())
      )
    );
  }, [searchText]);
  function handleChange(e) {
    e.preventDefault();
    setSearchText(e.target.value);
    if (!e.target.value.length >= 0) {
      setProjectData(projects);
    }
  }
  return (
    <ProjectsStyle>
      <div className="projects-container">
        <SectionTitle>
          <p className="service_p">Some of my recent works</p>
          <h1 className="service_h2">Projects</h1>
        </SectionTitle>
        <div className="projects-search">
          <form>
            <input
              type="text"
              className="search-bar"
              value={searchText}
              onChange={handleChange}
              placeholder="Project Name"
            />
            <MdSearch className="searchIcon"></MdSearch>
          </form>
        </div>
        <div className="projects-items">
          {projectData?.map((project, index) => (
            <ProjectItem
              key={index}
              image={project.img}
              title={project.name}
              info={project.desc}
              demo={project.demo}
              source={project.source}
            />
          ))}
        </div>
      </div>
      <ContactBlock />
      <Footer />
    </ProjectsStyle>
  );
}

export default Projects;
