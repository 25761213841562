import React from "react";
import SkillBar from "./SkillBar";
import { Link } from "react-router-dom";
function SkillsSection() {
  return (
    <div className="skills_container">
      <div className="skills-sec-wrapper">
        <div className="skills-sec-content">
          <p className="skills-sec-subhead">My development backpack</p>
          <h2 className="skills-sec-h2">Skills</h2>
          <p className="skills-sec-p">
            I love to learn new things and experiment with new technologies.
            Below are the skils that i use on regular basis to create new
            projects on the web.
          </p>
          <div className="skills-sec-btn">
            <Link to={"/skills"} className="btn-skills">
              Show More
            </Link>
          </div>
        </div>
        <div className="skills-sec-bar">
          <SkillBar title="MongoDB" value="75" />
          <SkillBar title="Express" value="70" />
          <SkillBar title="React.js" value="85" />
          <SkillBar title="Node.js" value="75" />
        </div>
      </div>
    </div>
  );
}

export default SkillsSection;
