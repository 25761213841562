import React from "react";
import { init } from "emailjs-com";
import emailjs from "emailjs-com";
import { Formik, Form, Field, ErrorMessage } from "formik";
const validateEmail = RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
);
function ContactForm() {
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: "",
      }}
      validate={(values) => {
        const errors = {};
        if (!values.name) errors.name = "Name is Required";
        if (!values.message) errors.message = "Message is Required";
        if (!values.email) {
          errors.email = "E-mail is Required";
        } else if (!validateEmail.test(values.email)) {
          errors.email = "E-mail is invalid";
        }
        return errors;
      }}
      onSubmit={(values, { setSubmitting }) => {
        const templateParams = {
          from_name: values.name,
          to_name: "Bharath Balasubramaniam",
          from_email: values.email,
          message: values.message,
        };
        setTimeout(() => {
          setSubmitting(true);
          init(`ZkPWTZzTmJeqvt1TL`);
          emailjs
            .send(
              `service_rctoxr7`,
              `template_c8uuyfka`,
              templateParams,
              `ZkPWTZzTmJeqvt1TL`
            )
            .then(
              function (response) {
                console.log("SUCCESS!", response.status, response.text);
                alert("Message sent", response.text);
              },
              function (error) {
                console.log("FAILED...", error);
              }
            );
        }, 500);
        values.email = "";
        values.name = "";
        values.message = "";
      }}
    >
      {({ isSubmitting }) => {
        return (
          <div className="con-form">
            <Form>
              <div className="form-group">
                <label htmlFor="name">
                  Your name
                  <Field
                    className="inputfield"
                    type="text"
                    id="name"
                    name="name"
                    placeholder="i.e. John doe"
                  />
                </label>
                <span className="error-msg">
                  <ErrorMessage name="name" />
                </span>
              </div>
              <div className="form-group">
                <label htmlFor="email">
                  Your email
                  <Field
                    type="text"
                    className="inputfield"
                    id="email"
                    name="email"
                    placeholder="johndoe@gmail.com"
                  />
                </label>
                <span className="error-msg">
                  <ErrorMessage name="email" />
                </span>
              </div>
              <div className="form-group">
                <label htmlFor="message">
                  Your message
                  <Field
                    as="textarea"
                    className="inputfield"
                    id="message"
                    name="message"
                    placeholder="your message"
                  />
                </label>
                <span className="error-msg">
                  <ErrorMessage name="message" />
                </span>
              </div>
              <button
                type="submit"
                className="form-btn"
                disabled={isSubmitting}
              >
                Send!
              </button>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
export default ContactForm;
