import React from "react";
import ContactBlock from "../Components/ContactBlock";
import Footer from "../Components/Footer";
import SkillBar from "../Components/SkillBar";
import Carousel from "react-elastic-carousel";
import "../Styles/pages.css";
import "../Styles/progressbar.css";
import html from "../Assets/skills/html.png";
import css from "../Assets/skills/css.png";
import bootstrap from "../Assets/skills/bootstrap.png";
import javascript from "../Assets/skills/javascript.png";
import react from "../Assets/skills/react.png";
import redux from "../Assets/skills/redux.png";
import typescript from "../Assets/skills/typescript.png";
import nextjs from "../Assets/skills/nextjs.png";
import vuejs from "../Assets/skills/vuejs.png";
import materialui from "../Assets/skills/material-ui.png";
import nodejs from "../Assets/skills/nodejs.png";
import mysql from "../Assets/skills/mysql.png";
import mongodb from "../Assets/skills/mongodb.png";
import python from "../Assets/skills/python.png";
import npm from "../Assets/skills/npm.png";
import github from "../Assets/skills/github.png";
import postman from "../Assets/skills/postman.png";
import express from "../Assets/skills/express.png";
import SectionTitle from "../Components/SectionTitle";
const skillsPng = [
  { id: 1, png: html, title: "HTML" },
  { id: 2, png: css, title: "CSS" },
  { id: 3, png: bootstrap, title: "Bootstrap" },
  { id: 4, png: javascript, title: "Javascript" },
  { id: 5, png: react, title: "React.js" },
  { id: 6, png: redux, title: "Redux" },
  { id: 7, png: typescript, title: "Typescript" },
  { id: 8, png: nextjs, title: "Next.js" },
  { id: 9, png: vuejs, title: "Vue.js" },
  { id: 10, png: materialui, title: "Material UI" },
  { id: 11, png: mysql, title: "MYSQL" },
  { id: 12, png: mongodb, title: "MongoDB" },
  { id: 13, png: express, title: "Express" },
  { id: 14, png: nodejs, title: "Node.js" },
  { id: 15, png: python, title: "Python" },
  { id: 16, png: npm, title: "NPM" },
  { id: 17, png: github, title: "Git" },
  { id: 18, png: postman, title: "Postman" },
];
const breakPoints = [
  { width: 400, itemsToShow: 2, itemsToScroll: 2 },
  { width: 450, itemsToShow: 3, itemsToScroll: 2 },
  // { width: 900, itemsToShow: 3, itemsToScroll: 2 },
  { width: 1200, itemsToShow: 5, itemsToScroll: 2 },
];
function Skills() {
  ///////////////
  const carouselRef = React.useRef(null);
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the last item, go to first item
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      // we hit the first item, go to last item
      carouselRef.current.goTo(skillsPng.length);
    }
  };
  //////////////
  return (
    <div className="skills-container">
      <SectionTitle>
        <p className="service_p">My development backpack</p>
        <h1 className="service_h2">Skills</h1>
      </SectionTitle>
      <div className="skills-wrapper">
        <h2 className="progress-subheading align-front">Front End</h2>
        <div className="progresses-wrapper">
          <SkillBar title={"HTML"} value="90" delay="0.1" />
          <SkillBar title={"CSS"} value="90" delay="0.2" />
          <SkillBar title={"Bootstrap"} value="75" delay="0.3" />
          <SkillBar title={"Javascript"} value="80" delay="0.4" />
          <SkillBar title={"Typescript"} value="80" delay="0.5" />
          <SkillBar title={"React.js"} value="85" delay="0.6" />
          <SkillBar title={"Redux"} value="75" delay="0.7" />
          <SkillBar title={"Next.js"} value="80" delay="0.8" />
          <SkillBar title={"Vue.js"} value="65" delay="0.9" />
        </div>
      </div>
      <div className="skills-wrapper">
        <h2 className="progress-subheading align-back">Back End</h2>
        <div className="progresses-wrapper">
          <SkillBar title={"Node.js"} value="75" delay="1" />
          <SkillBar title={"Express"} value="70" delay="1.1" />
          <SkillBar title={"MYSQL"} value="60" delay="1.2" />
          <SkillBar title={"Monogodb"} value="75" delay="1.3" />
          <SkillBar title={"Mongoose"} value="70" delay="1.4" />
          <SkillBar title={"Python"} value="40" delay="1.5" />
        </div>
      </div>
      <div className="skills-wrapper">
        <h2 className="progress-subheading align-front">Tools</h2>
        <div className="progresses-wrapper">
          <SkillBar title={"Git"} value="70" delay="1.6" />
          <SkillBar title={"Postman"} value="70" delay="1.7" />
          <SkillBar title={"Photoshop"} value="60" delay="1.8" />
        </div>
      </div>
      <div className="skills-wrapper">
        <h2 className="progress-subheading">Soft Skills</h2>
        <div className="progresses-wrapper">
          <SkillBar title={"Communication"} value="80" delay="1.9" />
          <SkillBar title={"Creativity"} value="70" delay="2" />
          <SkillBar title={"Adaptability"} value="80" delay="2.1" />
        </div>
      </div>
      <div className="skills-wrapper">
        <h2 className="progress-subheading align-back">Languages</h2>
        <div className="progresses-wrapper">
          <SkillBar title={"Tamil"} value="95" delay="2.2" />
          <SkillBar title={"English"} value="80" delay="2.3" />
          <SkillBar title={"French"} value="35" delay="2.4" />
        </div>
      </div>
      <div className="skills-carousel">
        <Carousel
          className="carousel-skills"
          breakPoints={breakPoints}
          ref={carouselRef}
          onPrevStart={onPrevStart}
          onNextStart={onNextStart}
          disableArrowsOnEnd={false}
          enableAutoPlay={true}
          autoPlaySpeed={2500}
          itemPadding={[0, 10]}
        >
          {skillsPng.map((obj) => {
            return (
              <div key={obj.id} className="skillpng-wrapper">
                <h2 className="skillpng-title">{obj.title}</h2>
                <img src={obj.png} alt="skills-png" className="skillsicon" />
              </div>
            );
          })}
        </Carousel>
      </div>

      {/* <Carousel
  className='carousel-university'
  breakPoints={breakPoints}
  ref={carouselRef}
  onPrevStart={onPrevStart}
  onNextStart={onNextStart}
  disableArrowsOnEnd={false}
> */}

      {/* <img src={express} alt="service_des" className="skillsicon" /> */}
      <ContactBlock />
      <Footer />
    </div>
  );
}

export default Skills;
