import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation } from "swiper";
import SectionTitle from "./SectionTitle";
import projects from "../Data/projects";
import ProjectItem from "./ProjectItem";
import "swiper/swiper-bundle.min.css";
import styled from "styled-components";
SwiperCore.use([Navigation]);
const ProjectSectionStyle = styled.div`
  padding: 2rem;
  .project-items {
    display: flex;
    gap: 3rem;
    margin-top: 5rem;
  }
  .swiper-container {
    padding-top: 8rem;
    max-width: 100%;
    margin: 0rem;
  }
  .swiper-button-prev,
  .swiper-button-next {
    position: absolute;
    height: 50px;
    width: 50px;
    background-color: black;
    z-index: 10;
    right: 100px;
    top: 0;
    left: auto;
    transform: translateY(50%);
    color: #b1b1b0;
    border-radius: 8px;
  }
  .swiper-button-next {
    right: 40px;
  }
  .swiper-button-next:hover,
  .swiper-button-prev:hover {
    color: white;
    background-color: #ff8906;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 2rem;
  }
  @media only screen and (max-width: 860px) {
    .project-items {
      flex-direction: column;
      max-width: 400px;
      margin-top: 7rem;
      gap: 5rem;
    }
  }
`;
function ProjectSection() {
  return (
    <ProjectSectionStyle>
      <div className="project-sec-container">
        <div className="project-sec-heading">
          <SectionTitle>
            <p className="service_p">Some of my recent works</p>
            <h1 className="service_h2">Projects</h1>
          </SectionTitle>
        </div>
        <div className="projects-items">
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            navigation
            breakpoints={{
              //when window width is <=640px
              280: {
                slidesPerView: 1,
              },
              //when window width is <=768px
              768: {
                slidesPerView: 2,
              },
              //when window width is <=1200px
              1200: {
                slidesPerView: 3,
              },
            }}
          >
            {projects.map((project, index) => {
              if (index >= 6) {
                // eslint-disable-next-line array-callback-return
                return;
              }
              return (
                <SwiperSlide key={project.id}>
                  <ProjectItem
                    key={index}
                    image={project.img}
                    title={project.name}
                    info={project.desc}
                    demo={project.demo}
                    source={project.source}
                  />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </ProjectSectionStyle>
  );
}

export default ProjectSection;
