import React from "react";
import SectionTitle from "./SectionTitle";
import ContactInfoItem from "./ContactInfoItem";
import ContactForm from "./ContactForm";
function ContactSection() {
  return (
    <div>
      <div className="contact-sec-container">
        <SectionTitle>
          <p className="contact-p">Get in touch</p>
          <h2 className="contact-h2">Contact</h2>
        </SectionTitle>
        <div className="contact-sec">
          <div className="contact-left">
            <ContactInfoItem />
          </div>
          <div className="contact-right">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContactSection;
