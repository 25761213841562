import React from "react";

function AboutInfoItem({ title, items }) {
  return (
    <div className="info-item-wrapper">
      <h3 className="info-item-title">{title}</h3>
      <div className="info-item-items">
        {items.map((item, index) => (
          <p className="info-item" key={index}>
            {item}
          </p>
        ))}
      </div>
    </div>
  );
}

export default AboutInfoItem;
