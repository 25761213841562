import React from "react";
import ContactBlock from "../Components/ContactBlock";
import Footer from "../Components/Footer";
// import { Link } from "react-router-dom";
import "../Styles/pages.css";
import ServicesSection from "../Components/ServicesSection";
import HomePtext from "../Components/HomePtext";
import aboutImg from "../Assets/images/about_read.png";
import AboutInfoItem from "../Components/AboutInfoItem";
function About() {
  return (
    <>
      <div className="about-container">
        <div className="top-section">
          <div className="left-part">
            <p className="about-subheading">
              Hello, I am <span className="about-span">Bharath</span>
            </p>
            <h2 className="about-heading">A Fullstack Web developer</h2>
            <div className="about-info">
              <HomePtext>
                <p className="about-content">
                  I am from Puducherry, India. A place of beaches and heritage.
                  I'm highly skilled in React.js and good in back-end part too.
                  I always try to design stuff in a unique way. My core skill is
                  based on Javascript and i love to do most of the things using
                  Javascript. I love to make the Web more open to the world!
                  <br />
                  <br />I started coding since 2020. I always try to learn
                  something new everyday.I think coding is just like an art for
                  me I love it. I am proud of myself because i build things that
                  helps users have a better experience on Web. I enjoy playing
                  Chess and reading books in my free time. You can connect me
                  via email.
                  <br />
                  <br />
                  My vision is to make myself in such a way that I am always
                  part of the solution, not the problem"
                  <br />
                  <br />
                </p>
              </HomePtext>
            </div>
            <br />
            <div className="download_cv">
              <a
                href="https://drive.google.com/file/d/1N0LhHI5of1KNw4QJ4JS1FdSjOhS6Wv86/view"
                target="_blank"
                rel="noreferrer"
                className="download_cv_btn"
              >
                Download CV
              </a>
            </div>
          </div>
          <div className="right-part">
            <img src={aboutImg} alt="my_img" />
          </div>
        </div>
        <br />
        <div className="mid-section">
          <div className="about-info-items">
            <h2 className="about-info-heading">EDUCATION</h2>
            <AboutInfoItem
              title="School"
              items={[`St.Mary's higher secondary school, Karaikal`]}
            />
            <AboutInfoItem
              title="College"
              items={[
                `B.TECH, Sri Manakula Vinayaga Engineering College, Puducherry`,
              ]}
            />
            <AboutInfoItem
              title="Varsity"
              items={[`MBA, Central University of Pondicherry, Puducherry`]}
            />
            <h2 className="about-info-heading">MY SKILLS</h2>
            <AboutInfoItem
              title="Front End"
              items={[`HTML`,
                `CSS`,
                `Javascript`,
                `Typescript`,
                `React.js`,
                `Redux`,
                `Next.js`,
                `Vue.js`]}
            />
            <AboutInfoItem
              title="Back End"
              items={[`Node.js`, `MYSQL`, `MONGODB`, `Express`]}
            />
            <AboutInfoItem title="Design" items={[`Photoshop`]} />
            <h2 className="about-info-heading">EXPERIENCE</h2>
            <AboutInfoItem
              title="2020 - 2022"
              items={[`Junior developer at Kaypro Technologies, Chennai`]}
            />
            <AboutInfoItem
              title="2022 - 2023"
              items={[`Software developer at Casperon Technologies, Chennai`]}
            />
            <AboutInfoItem
              title="2023 - Present"
              items={[`Senior software engineer at Novac Technologies, Chennai`]}
            />
          </div>
        </div>
      </div>
      <ServicesSection />
      <ContactBlock />
      <Footer />
    </>
  );
}

export default About;
