import React from "react";
// import call from "../Assets/phone-call.png";
// import mail from "../Assets/mail.png";
// import location from "../Assets/location.png";
import "../Styles/pages.css";
function ContactInfoItem() {
  return (
    <div className="contactinfoitems">
      <div className="con-wrapper">
        <div className="con-icon">
          <img src={require("../Assets/phone-call.png")} alt="contact_des" className="contacticon" />
        </div>
        <div className="con-info">
          <p className="con-p">+91 9500583357</p>
        </div>
      </div>
      <div className="con-wrapper">
        <div className="con-icon">
          <img src={require("../Assets/mail.png")} alt="contact_des" className="contacticon" />
        </div>
        <div className="con-info">
          <p className="con-p">bharath_dev@outlook.com</p>
        </div>
      </div>
      <div className="con-wrapper">
        <div className="con-icon">
          <img src={require("../Assets/location.png")} alt="contact_des" className="contacticon" />
        </div>
        <div className="con-info">
          <p className="con-p">Puducherry, India</p>
        </div>
      </div>
    </div>
  );
}

export default ContactInfoItem;
