import { v4 as uuidv4 } from "uuid";
import chat from "../Assets/projects/chat.png";
import appointment from "../Assets/projects/appointment-booking.png";
import url from "../Assets/projects/url-shortner.png";
import dictionary from "../Assets/projects/dictionary.png";
import ecart from "../Assets/projects/ecart1.png";
import food from "../Assets/projects/food.png";
import movie from "../Assets/projects/movie.png";
import news from "../Assets/projects/news.png";
import tinder from "../Assets/projects/tinder.png";

const projects = [
  {
    id: uuidv4(),
    name: "E-Cart",
    desc: "The e-commerce application built with MERN along with admin panel for create,update and delete products",
    img: ecart,
    demo: "https://bv-cart.netlify.app/",
    source: "https://github.com/bharath-balasubramaniam/BV-Cart",
  },
  {
    id: uuidv4(),
    name: "WhatsApp Clone",
    desc: "The Chat application with features like one on one and user can create group, add and remove users. ",
    img: chat,
    demo: "https://bv-chat.netlify.app/",
    source: "https://github.com/bharath-balasubramaniam/BV-Chat-MERN",
  },
  {
    id: uuidv4(),
    name: "Appointment App",
    desc: "The Appointment booking application with features like create multiple time slots, get appointments and more.",
    img: appointment,
    demo: "https://appointment-booking-admin.netlify.app/",
    source: "https://github.com/bharath-balasubramaniam/appointment-booking-MERN-Frontend",
  },
  
  {
    id: uuidv4(),
    name: "URL Shortener",
    desc: "URL Shortener application which has Login authentication, It also captures the number of clicks on the short URL generated.",
    img: url,
    demo: "https://url-shortner-mern-app.netlify.app/",
    source: "https://github.com/bharath-balasubramaniam/URL-Shortner",
  },
  {
    id: uuidv4(),
    name: "Web Dictionary",
    desc: "Using this app you can search meanings for 12 languages. Phonetics are available only for english",
    img: dictionary,
    demo: "https://word-dictionary-app.netlify.app/",
    source: "https://github.com/bharath-balasubramaniam/BV-Dictionary",
  },
  {
    id: uuidv4(),
    name: "Tinder Clone",
    desc: "A clone of the dating app called tinder with no functionalities and this was my first fullstack project.",
    img: tinder,
    demo: "https://lucid-engelbart-34aba8.netlify.app/",
    source: "https://github.com/bharath-balasubramaniam/dating_app-MERN",
  },
  {
    id: uuidv4(),
    name: "New York Times News",
    desc: "This application is a javascript application which retrives the news from new york times API and displays it in the UI",
    img: news,
    demo: "https://bharath-balasubramaniam.github.io/Newyork-Times-API-UI/",
    source: "https://github.com/bharath-balasubramaniam/Newyork-Times-API-UI",
  },
  {
    id: uuidv4(),
    name: "Food Recipe",
    desc: "You can search any food recipe along with the nutrients table so you can have your food based on your diet plan",
    img: food,
    demo: "https://bharath-balasubramaniam.github.io/Food-Recipe-API-UI/",
    source: "https://github.com/bharath-balasubramaniam/Food-Recipe-API-UI",
  },
  {
    id: uuidv4(),
    name: "Movie Database App",
    desc: "This application provides details for all movies and series even you can search your regional movies.",
    img: movie,
    demo: "https://modest-allen-45ef04.netlify.app/",
    source:
      "https://github.com/bharath-balasubramaniam/DOM-Promise-UI-IMDB_Api",
  },
];

export default projects;
