import React from "react";
import HomePtext from "./HomePtext";
import SectionTitle from "./SectionTitle";
import { Link } from "react-router-dom";
import about_img from "../Assets/images/about.png";
function AboutSection() {
  return (
    <div className="about_container">
      <div className="about_info">
        <div className="about_lt">
          <SectionTitle>
            <p className="about_p">Let me introduce myself</p>
            <h1 className="about_h2">About Me</h1>
          </SectionTitle>
          <HomePtext>
            <p className="about_content">
              I am a skilled MERN Stack developer. I have the potential of
              converting ideas into working products. I'm good in both Front end
              and Back end Web development. I have a special interest in the
              field of Artificial Intelligence and Data Science.
            </p>
          </HomePtext>
          <div className="about_sec_btns">
            <div className="about_sec_btn">
              <Link to={"/about"} className="btn_about">
                Read More
              </Link>
            </div>
            <div className="about_sec_btn">
              <Link to={"/projects"} className="btn_about_work">
                Works
              </Link>
            </div>
          </div>
        </div>
        <div className="about_rt">
          <img src={about_img} alt="about.jpg" className="about_img" />
        </div>
      </div>
    </div>
  );
}

export default AboutSection;
