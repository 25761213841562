import React from "react";
import "../Styles/pages.css";
import map from "../Assets/images/map.png";
import styled from "styled-components";
const MapStyles = styled.div`
  background: url(${map}) no-repeat center;
  border: 3px solid #a7a9be;
  min-height: 400px;
  margin: 2rem;
  .map-card-wrapper {
    position: relative;
    min-height: 400px;
  }
  .map-card {
    position: relative;
    left: 40%;
    top: 20rem;
    padding: 2rem;
    background: rgba(0, 0, 0, 0.8);
    width: 100%;
    max-width: 280px;
    border-radius: 12px;
  }
  a {
    font-size: 1.7rem;
    display: inline-block;
    text-decoration: underline;
    font-family: "Architects Daughter", cursive;
    color: #a7a9be;
  }
  @media only screen and (max-width: 110px) {
    background-position: 80% center;
  }
  @media only screen and (max-width: 766px) {
    .map-card-wrapper {
      max-width: none;
      right: auto;
    }
    .map-card {
      left: 5%;
    }
  }
`;
function Map() {
  return (
    <MapStyles>
      <div className="map-card-wrapper">
        <div className="map-card">
          <h3 className="map-card-heading">Here is me</h3>
          <p className="map-card-p">Karaikal, Puducherry, India</p>
          <a
            href="https://www.google.com/maps/@10.927408,79.7772861,14z"
            target="_blank"
            rel="noreferrer"
          >
            Open in Google Map
          </a>
        </div>
      </div>
    </MapStyles>
  );
}

export default Map;
