import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
const ProjectItemStyles = styled.div`
  .projectItem-img {
    width: 90%;
    height: 300px;
    overflow: hidden;
    padding: 0rem 2rem;
    border-radius: 12px;
    display: inline-block;
    border: 3px solid gray;
    background-color: black;
    img {
      height: 100%;
      transition: transform 1s;
    }
  }
  .projectItem-img img:hover {
    transform: scale(1.1);
  }
  .projectItem-info {
    margin-top: 1rem;
    background-color: black;
    padding: 1rem 2rem;
    border-radius: 12px;
    width: 90%;
  }
  .projectItem-title {
    font-size: 2.7rem;
    font-family: "Amiri", serif;
    color: #fffffe;
  }
  .projectItem-desc {
    font-size: 2.1rem;
    color: #b8c1ec;
    line-height: 2.5rem;
    font-family: "Zen Kaku Gothic Antique", sans-serif;
  }
  @media only screen and (max-width: 860px) {
    .projectItem-img {
      width: 100%;
    }
    .projectItem-info {
      width: 100%;
    }
  }
`;
const ProjectItem = ({ image, title, info, demo, source }) => {
  return (
    <ProjectItemStyles>
      <Link to="/projects" className="projectItem-img">
        <img src={image} alt={`${image}`} />
      </Link>
      <div className="projectItem-info">
        <h3 className="projectItem-title">{title}</h3>
        <p className="projectItem-desc">{info}</p>
        <div className="button-wrapper">
          <div className="button-demo">
            <a
              href={demo}
              target="_blank"
              rel="noreferrer"
              className="btn-demo"
            >
              Demo
            </a>
          </div>
          <div className="button-source">
            <a
              href={source}
              target="_blank"
              rel="noreferrer"
              className="btn-source"
            >
              Source Code
            </a>
          </div>
        </div>
      </div>
    </ProjectItemStyles>
  );
};

export default ProjectItem;
