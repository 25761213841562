import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../Styles/navbar.css";
import { MdClose, MdMenu } from "react-icons/md";
function Navbar() {
  const [navOpen, setNavOpen] = useState(false);
  return (
    <div className="navmenu">
      <div className="navbaropen" onClick={() => setNavOpen(!navOpen)}>
        <MdMenu />
      </div>
      <ul className={!navOpen ? "navitems hide-items" : "navitems"}>
        <div
          className="navbarclose"
          onClick={() => setNavOpen(!navOpen)}
          role="button"
          onKeyDown={() => setNavOpen(!navOpen)}
          tabIndex={0}
        >
          <MdClose />
        </div>
        <li
          className="home"
          onClick={() => setNavOpen(!navOpen)}
          role="button"
          onKeyDown={() => setNavOpen(!navOpen)}
          tabIndex={0}
        >
          <NavLink to="/home">Home</NavLink>
        </li>
        <li
          className="about"
          onClick={() => setNavOpen(!navOpen)}
          role="button"
          onKeyDown={() => setNavOpen(!navOpen)}
          tabIndex={0}
        >
          <NavLink to="/about">About</NavLink>
        </li>
        <li
          className="skills"
          onClick={() => setNavOpen(!navOpen)}
          role="button"
          onKeyDown={() => setNavOpen(!navOpen)}
          tabIndex={0}
        >
          <NavLink to="/skills">Skills</NavLink>
        </li>
        <li
          className="projects"
          onClick={() => setNavOpen(!navOpen)}
          role="button"
          onKeyDown={() => setNavOpen(!navOpen)}
          tabIndex={0}
        >
          <NavLink to="/projects">Projects</NavLink>
        </li>
        <li
          className="contact"
          onClick={() => setNavOpen(!navOpen)}
          role="button"
          onKeyDown={() => setNavOpen(!navOpen)}
          tabIndex={0}
        >
          <NavLink to="/contact">Contact</NavLink>
        </li>
      </ul>
    </div>
  );
}

export default Navbar;
